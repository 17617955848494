@import './index.less';

.app__content {
  background-color: #fff;
  padding: @padding-md @padding-md 0;
  min-height: calc(100vh - @layout-header-height);
}

.app__header {
  padding: 0 @padding-md !important;
}
