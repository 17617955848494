@import '../../../../../index.less';

.menu-drawer__drawer .ant-drawer-close {
  font-size: @font-size-base * 1.125;
  margin-right: 10px;
  padding: 0 !important;
}

.menu-drawer__trigger {
  color: @white !important;
  font-size: @font-size-base * 1.125;
}
