.acesso-login-page__container {
  background-image: url('../../../assets/easyassist-login-background.png');
  background-position: center center;
  display: flex;
  align-items: center;
  justify-content: center;

  height: 100vh;
  padding: 0.75rem;
  width: 100vw;
}

@media (min-width: 992px) {
  .acesso-login-page__container {
    align-items: center;
    justify-content: flex-end;
  }
}
