@import '~antd/dist/antd.less';

@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

@font-family: 'Roboto', sans-serif;

@info-color: #1890ff;
@primary-color: #ff4d4f; // #ed1b24

/* altura */
@font-size-base: 16px;
@font-size-sm: 14px;

@height-base: 40px;
@height-lg: 48px;
@height-sm: 32px;

@checkbox-size: 20px;

/* bordas */
@border-radius-base: 8px;

@checkbox-border-radius: 1px;

@switch-height: 30px;
@switch-min-width: 52px;

/* outras */
@layout-header-background: @primary-color;

@select-single-item-height-lg: @height-lg;

/* retaguarda */
@ea-floating-action-bar-height: @layout-header-height;
