.acesso-login-form__container {
  background-color: #fff;
  border-radius: 0.5rem !important;
  padding: 1rem !important;
  width: 100%;
}

@media (min-width: 576px) {
  .acesso-login-form__container {
    width: 480px;
  }
}

@media (min-width: 992px) {
  .acesso-login-form__container {
    margin-right: 2rem !important;
  }
}

@media (min-width: 1200px) {
  .acesso-login-form__container {
    margin-right: 4rem !important;
  }
}
