@import '../../../index.less';

.ea-floating-action-bar__container {
  border: @border-width-base solid @border-color-split;
  border-top-left-radius: @border-radius-base;
  height: @ea-floating-action-bar-height;
  padding: 0 @padding-md;

  display: flex;
  align-items: center;

  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 1;

  background-color: @white;
}
